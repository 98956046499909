.create-menu-dialog {
  display: flex;
  justify-content: flex-end !important;
  height: 100vh !important;
  width: 100vw;
}

*:focus {
  outline: 0;
}

.modal-create-menu-content {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 60vw;
  height: 100vh;
  padding-left: 10px;
  padding-right: 10px;
}

.modal-create-menu-content h2 {
  margin-left: 12px;
}

.modal-create-menu-container {
  display: flex;
  flex-direction: column;
}

.image-create-menu-wrapper {
  height: 25vh;
}

.tag-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: fit-content;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: -7px !important;
  padding-bottom: -7px !important;
  border-radius: 35px;
}

.tag-wrapper:hover {
  cursor: pointer;
  transition: 600ms;
}

.check-tag-img {
  transition: 400ms;
}

.bottom-total-cost-container p {
  font-weight: bold;
}

.ingredients-table-container {
  width: 100%;
}

.ingredients-table-container th {
  text-align: start;
}
.ingredients-table-container td {
}

.ingredients-table-container td p {
  white-space: nowrap;
  margin-top: 25px;
  align-self: center;
}

.inactiveButton {
  margin: 0 5px;
  color: #211f5c !important;
  border: none;
  background-color: #fdfdfd !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: fit-content;
}

.activeButton {
  margin: 0 5px;
  color: #fff !important;
  border: none;
  background-color: #211f5c !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: fit-content;
}

.MuiInputLabel-animated {
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.MuiFormLabel-root.Mui-focused {
  color: black;
}
